import { useState } from 'react'
import styled from 'styled-components'
import { Button, Icon, Loader, Search } from 'semantic-ui-react'
import { BasicButton } from 'app/components/ui/BasicButton'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { selectCurrentUser } from '../../../../store/authSlice'
import { selectEnrollmentId } from 'app/store/uiSlice'
import { useCreateForumPostMutation } from 'app/store/apiHubSlice'
import { setAssigment } from '../../../../store/assigmentCreationSlice'
import NewVideo from './NewVideo'
import NewAudio from './NewAudio'
import uploadFile from '../../../../utils/uploadFile'
import ParentInformer from '../../../../utils/ParentInformer'
import { Video } from 'video-metadata-thumbnails'
import NewPost from './NewPost'

export default function Block({
  title,
  description,
  image,
  showButton,
  hashtags = '',
  format = 'standard',
  answerTypes = [],
  assignment,
  documentId,
  searchValue,
  onSearchChange,
}) {
  const [selectedAnswerType, setSelectedAnswerType] = useState(null)
  const [creating, setCreating] = useState(false)
  const currentUser = useSelector(selectCurrentUser)
  const [createForumPost] = useCreateForumPostMutation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const enrollmentId = useSelector(selectEnrollmentId)
  const [errorMessage, setErrorMessage] = useState(null)
  const answerButtons = []
  let content

  const onCancelAnswerType = () => {
    setSelectedAnswerType(null)
  }

  const onPostMediaAnswer = async (newFile, uploadThumbnail = false) => {
    setErrorMessage(null)
    setCreating(true)

    try {
      // Upload file to s3
      const file = await uploadFile(
        newFile,
        `assigments/${assignment.id}/${currentUser.id}`
      )
      const filesToUpload = [file]

      // Get and upload thumbnail
      if (uploadThumbnail) {
        const video = new Video(newFile)
        const thumbnails = await video.getThumbnails({ quality: 0.6, end: 1 })
        const thumbnailFile = new File(
          [thumbnails[0].blob],
          new Date().toISOString(),
          { type: thumbnails[0].blob.type }
        )
        const thumbnail = await uploadFile(
          thumbnailFile,
          `assigments/${assignment.id}/${currentUser.id}/thumbnail`,
          process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME,
          true
        )
        filesToUpload.push(thumbnail)
      }

      // Create forum post
      const forumPost = await createForumPost({
        userId: currentUser.id,
        assignmentId: assignment.id,
        documentId: documentId,
        content: '',
        files: filesToUpload,
        title: assignment.title,
        enrollmentId,
      })

      if (!forumPost.data) {
        setErrorMessage('Ocurrió un error al publicar tu entrada.')
        return
      }

      window.location.reload()
      ParentInformer.sendSubmission(assignment)
    } catch (error) {
      console.log(error)
    }

    setCreating(false)
  }

  const editForum = () => {
    dispatch(setAssigment({ ...assignment }))
    navigate(`/forum/${assignment.id}/edit?user_id=${currentUser.id}`, {
      state: { prev: location.search },
    })
  }

  // Check if student can answer with text
  if (answerTypes.includes('text') || answerTypes.length === 0) {
    answerButtons.push(
      <AnswerTypeButton
        onClick={() => setSelectedAnswerType('text')}
        key="text"
      >
        <NoMarginIcon name="write" size="big" />
        <span style={{ marginTop: '1rem', color: 'white' }}>TEXTO</span>
      </AnswerTypeButton>
    )
  }

  // Check if student can answer with video
  if (answerTypes.includes('video')) {
    answerButtons.push(
      <AnswerTypeButton
        onClick={() => setSelectedAnswerType('video')}
        key="video"
      >
        <NoMarginIcon name="video" size="big" />
        <span style={{ marginTop: '1rem', color: 'white' }}>VIDEO</span>
      </AnswerTypeButton>
    )
  }

  // Check if student can answer with audio
  if (answerTypes.includes('audio')) {
    answerButtons.push(
      <AnswerTypeButton
        onClick={() => setSelectedAnswerType('audio')}
        key="audio"
      >
        <NoMarginIcon name="microphone" size="big" />
        <span style={{ marginTop: '1rem', color: 'white' }}>AUDIO</span>
      </AnswerTypeButton>
    )
  }

  if (creating) {
    content = (
      <div>
        <Loader
          active
          inline="centered"
          content={
            selectedAnswerType === 'video'
              ? 'Publicando video'
              : 'Publicando audio'
          }
        />
      </div>
    )
  } else if (showButton && !selectedAnswerType && format === 'small') {
    content = (
      <>
        <HowWouldYouLikeToAnswer>
          ¿Cómo te gustaría responder?
        </HowWouldYouLikeToAnswer>
        <AnswerButtonsContainer>
          {answerButtons.map((b) => b)}
        </AnswerButtonsContainer>
      </>
    )
  } else if (selectedAnswerType === 'video') {
    content = (
      <NewVideo onPost={onPostMediaAnswer} onCancel={onCancelAnswerType} />
    )
  } else if (selectedAnswerType === 'audio') {
    content = (
      <NewAudio onPost={onPostMediaAnswer} onCancel={onCancelAnswerType} />
    )
  } else if (selectedAnswerType === 'text') {
    content = (
      <NewPost
        user={currentUser}
        assignment={assignment}
        documentId={documentId}
        onCancel={onCancelAnswerType}
      />
    )
  }

  if (format === 'small') {
    return (
      <SmallForumContainer>
        <SmallFormatMain>
          <Title style={{ marginTop: '15px' }}>{title}</Title>
          {content}
        </SmallFormatMain>
      </SmallForumContainer>
    )
  }

  return (
    <MainContainer>
      <Main>
        {image && <Cover src={image} />}
        <ContentContainer>
          <Title>{title}</Title>
          <div style={{ width: '100%' }}>
            <p>{hashtags}</p>
          </div>
          <StyledBlock>
            <Description
              dangerouslySetInnerHTML={{ __html: description }}
            ></Description>
            {!currentUser.isStudent && (
              <SearchBarContainer>
                <Search
                  style={{ marginTop: '1rem' }}
                  placeholder="Busca un estudiante"
                  showNoResults={false}
                  value={searchValue}
                  onSearchChange={onSearchChange}
                />
                <BasicButton
                  style={{ marginTop: '1rem', marginBottom: '2rem' }}
                  onClick={editForum}
                >
                  Editar foro
                </BasicButton>
              </SearchBarContainer>
            )}
          </StyledBlock>
          {showButton && !selectedAnswerType && (
            <AnswerButton onClick={() => setSelectedAnswerType('text')} primary>
              ¡Agrega tu respuesta!
            </AnswerButton>
          )}
          {content}
        </ContentContainer>
        {errorMessage && (
          <div style={{ textAlign: 'right' }}>
            <small style={{ color: 'red' }}>{errorMessage}</small>
          </div>
        )}
      </Main>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: space-around;
  font-family: cursive;
  text-align: left;
  font-family: system-ui;
  disabled: true;
  min-height: 100%;
  border-radius: 8px;
  color: inherit;
`
const SmallForumContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  justify-content: space-around;
  font-family: cursive;
  text-align: center;
  font-family: system-ui;
  disabled: true;
  color: inherit;
`
const Main = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px 12px 0 0;
  min-height: 100%;
  color: inherit;
`
const SmallFormatMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`
const Cover = styled.div`
  width: 100%;
  height: 200px;
  background: url(${(props) => props.src});
  background-size: cover;
`
const ContentContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
`
const Title = styled.p`
  font-size: 2em;
  margin-bottom: 5px;
  font-weight: 800;
  width: 100%;
  color: ${(props) => props.theme.text};
`
const StyledBlock = styled.div`
  width: 100%;
  font-weight: 400;
  font-family: system-ui !important;
  font-size: 1.1em;
  line-height: 1em;
  margin-top: 10px;
  padding-left: 2px;
  padding-right: 2px;
`
const Description = styled.div`
  color: ${(props) => props.theme.textSecondary} !important;
`
const AnswerButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 40px;
`
const AnswerTypeButton = styled(Button)`
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  margin-right: 0 !important;
  padding: 1.5rem !important;
  border-radius: 16px !important;
  background-color: #293845 !important;
  color: white !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 10px 30px 0px !important;
  transition: all 0.2s ease-in-out !important;
  :hover {
    transform: scale(1.1);
  }
  font-weight: 500 !important;
  letter-spacing: 0.916667px !important;
`
const NoMarginIcon = styled(Icon)`
  margin-left: 0 !important;
  margin-right: 0 !important;
`
const HowWouldYouLikeToAnswer = styled.span`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.textSecondary};
`
const SearchBarContainer = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
`
const AnswerButton = styled(Button)`
  margin-top: 20px !important;
`