// React
import { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { selectStudentAnsweredForum, selectViewOnly } from 'app/store/uiSlice'
import { setAssigment } from 'app/store/assigmentCreationSlice'
import { selectCurrentUser } from 'app/store/authSlice'

// UI libraries
import { Search, Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

// Components
import Block from './Block'
import AskPostsFeed from './AskPostsFeed'
import ForumPostsFeed from './ForumPostsFeed'
import { BasicButton } from 'app/components/ui/BasicButton'
import SubmissionDetails from 'app/components/SubmissionDetails'
import SubmissionFeedback from 'app/elements/SubmissionFeedback'

// Other
import randomImage from 'app/utils/randomImage'

export default function PostsList({
  posts,
  assignment,
  documentUuid,
  submission,
  searchValue,
  onSearchChange,
  hasMore,
  loadMore,
  isLoading,
}) {
  const divRef = useRef(null)
  const studentAnsweredForum = useSelector(selectStudentAnsweredForum)
  const currentUser = useSelector(selectCurrentUser)
  const viewOnly = useSelector(selectViewOnly)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const showFeed =
    (currentUser.isStudent && studentAnsweredForum) ||
    !currentUser.isStudent ||
    (currentUser.isStudent && viewOnly)

  const config =
    assignment.configuration && typeof assignment.configuration === 'string'
      ? JSON.parse(assignment.configuration.replaceAll('=>', ':'))
      : typeof assignment.configuration === 'object'
      ? assignment.configuration
      : {}

  const image = config && config.image ? config.image : randomImage()

  let showBlock =
    assignment.configuration.format === 'small'
      ? currentUser.isStudent && !studentAnsweredForum
      : true

  const editAsk = () => {
    dispatch(setAssigment({ ...assignment }))
    navigate(`/forum/${assignment.id}/edit?user_id=${currentUser.id}`, {
      state: { prev: location.search },
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      }}
      ref={divRef}
    >
      {showBlock && (
        <div style={{ width: '100%' }}>
          {/* Details */}
          {submission && (
            <>
              <SubmissionDetails submission={submission} />
              <br />
            </>
          )}
          <Block
            title={assignment.title}
            description={assignment.description}
            image={image}
            showButton={
              currentUser.isStudent && !studentAnsweredForum && !viewOnly
            }
            format={assignment.configuration.format}
            answerTypes={assignment.configuration.answerTypes || []}
            assignment={assignment}
            documentId={documentUuid}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
          />
        </div>
      )}
      {assignment.configuration.format === 'small' &&
        (studentAnsweredForum || !currentUser.isStudent) && (
          <>
            {/* Title */}
            <Title style={{ marginTop: '10px' }}>{assignment.title}</Title>
            {/* Details */}
            {submission && <SubmissionDetails submission={submission} />}
            {/* Edit Ask */}
            {!currentUser.isStudent && (
              <SearchBarContainer>
                <Search
                  style={{ marginTop: '1rem' }}
                  placeholder="Busca un estudiante"
                  showNoResults={false}
                  value={searchValue}
                  onSearchChange={onSearchChange}
                />
                <div>
                  <BasicButton
                    style={{
                      margin: 'auto',
                      marginTop: '1rem',
                      marginBottom: '2rem',
                    }}
                    onClick={editAsk}
                  >
                    Edit
                  </BasicButton>
                </div>
              </SearchBarContainer>
            )}
          </>
        )}
      {showFeed && (
        <FeedContainer style={{ width: '100%' }}>
          {assignment.configuration.format === 'small' ? (
            <AskPostsFeed
              assignment={assignment}
              posts={posts || []}
              answerTypes={assignment.configuration.answerTypes || []}
              searchValue={searchValue}
            />
          ) : (
            <ForumPostsFeed
              assignment={assignment}
              user={currentUser}
              posts={posts || []}
              format={assignment.configuration.format}
              answerTypes={assignment.configuration.answerTypes || []}
              searchValue={searchValue}
              submission={submission}
            />
          )}
          <div style={{ width: '100%', textAlign: 'center' }}>
            {hasMore && (
              <Button
                basic
                onClick={loadMore}
                loading={isLoading}
                disabled={isLoading}
                style={{ margin: '0' }}
              >
                Mostrar más{' '}
                <Icon style={{ margin: '0 0 0 0.2rem' }} name="chevron down" />
              </Button>
            )}
          </div>
        </FeedContainer>
      )}
      {submission &&
        (assignment.configuration?.requiresFeedback || assignment.enableAiFeedback) && <SubmissionFeedback />}
    </div>
  )
}

const FeedContainer = styled.div`
  height: 100% !important;
  margin: 0;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
const Title = styled.p`
  font-size: 1.5em;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif !important;
`
const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  width: 100%;
`
