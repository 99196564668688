import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import timeAgo from '../../../../utils/timeAgo'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedPost } from '../../../../store/uiSlice'
import Avatar from '../../../shared/Avatar'
import { Icon } from 'semantic-ui-react'
import PostActions from './PostActions'
import ReactPlayer from 'react-player'
import getFileUrl from '../../../../utils/getFileUrl'
import './PostsFeed.css'
import ParentInformer from '../../../../utils/ParentInformer'
import { convertFiles } from '../../../../utils/convertFiles'
import Fuse from 'fuse.js'
import { selectCurrentUser } from 'app/store/authSlice'
import { getRandomColor } from 'app/utils/defaultColors'
import { getContrastColorHex } from 'app/utils'

const extractContent = (post) => {
  return post.content
    ? new DOMParser().parseFromString(post.content, 'text/html').documentElement
      .textContent
    : ''
}

const getAnswerType = (post) => {
  const files = convertFiles(post.files)
  const firstFile = files ? files[0] : null
  const answerType = firstFile ? firstFile.file_type?.split('/')[0] : null

  if (answerType) {
    return answerType
  }

  return 'text'
}

function PostPreview({ post, answerTypes, backgroundColor }) {
  const firstFile =
    !!post.files &&
      typeof post.files !== 'string' &&
      Object.keys(post.files).length !== 0
      ? post.files[0]
      : null
  const answerType = getAnswerType(post)
  const [fileUrl, setFileUrl] = useState(null)
  const [thumbnailUrl, setThumbnailUrl] = useState('')

  // Get file url (if necessary)
  useEffect(() => {
    async function getMediaUrl() {
      if (!fileUrl && !!firstFile && answerType.includes(answerType)) {
        const url = await getFileUrl(
          firstFile?.file_url,
          process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
        )
        setFileUrl(url)

        if (answerType === 'video') {
          // Find thumbnail
          const thumbUrl = post.files.find((f) => f.file_type.includes('image'))
          const usableThumbUrl = await getFileUrl(
            thumbUrl?.file_url,
            process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
          )
          setThumbnailUrl(usableThumbUrl)
        }
      }
    }
    getMediaUrl()
  }, [])

  let content

  // Check if answer is of type media
  if (!!firstFile && answerTypes.includes(answerType)) {
    if (answerType === 'audio') {
      content = (
        <PreviewContainer>
          <MicCircle>
            <Icon style={{ margin: '0' }} name="volume up" />
          </MicCircle>
        </PreviewContainer>
      )
    } else if (answerType === 'video') {
      content = (
        <PreviewContainer answerType="video" className="video-post-preview">
          <ReactPlayer
            width="100%"
            height="100%"
            url={fileUrl}
            light={thumbnailUrl}
          />
        </PreviewContainer>
      )
    }
  } else {
    content = (
      <PreviewContainer
        style={{
          textAlign: 'left',
          padding: '1rem',
          color: getContrastColorHex(backgroundColor),
        }}
      >
        {GetContentText(post)}
      </PreviewContainer>
    )
  }

  return content
}

function GetContentText(post) {
  const content = extractContent(post).trim()
  const result =
    content.length > 115 ? content.substring(0, 115) + '...' : content
  return result
}

function Post({
  post,
  answerType,
  answerTypes,
  backgroundColor,
  userPost,
  user,
  onAddComment,
  onClick,
}) {
  const preselectedBackgroundColor =
    post.details?.backgroundColor ?? backgroundColor
  const colorContrast = getContrastColorHex(preselectedBackgroundColor)
  return (
    <PostContainer
      onClick={onClick}
      highlight={post.id === userPost?.id}
      answerType={answerType}
      key={post.id}
      backgroundColor={preselectedBackgroundColor}
    >
      {/* Content */}
      <PostContent color={backgroundColor ? '#3E4F67' : ''}>
        <PostPreview
          post={post}
          answerTypes={answerTypes}
          backgroundColor={preselectedBackgroundColor}
        />
      </PostContent>
      {/* User details */}
      <PostDetails>
        <Time color={colorContrast}>
          {timeAgo.format(Date.parse(post.createdAt))}
        </Time>
        <AvatarWrapper>
          <Avatar src={post.user?.avatarUrl} size={30} showBorder={false} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '5px',
            }}
          >
            <UserName color={colorContrast}>{`${post.user?.name
              ? post.user.name
              : post.user?.givenName +
              ' ' +
              post.user?.familyName1 +
              ' ' +
              post.user?.familyName2
              }`}</UserName>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <PostActions
                user={user}
                post={post}
                onAddComment={onAddComment}
                color={colorContrast}
              />
            </div>
          </div>
        </AvatarWrapper>
      </PostDetails>
    </PostContainer>
  )
}

export default function AskPostsFeed({
  assignment,
  posts,
  answerTypes,
  searchValue,
  submission,
}) {
  let dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const [, setWindowWidth] = useState(window.innerWidth)
  const userPost = posts.find((p) => p.userId === currentUser.id)
  let forumPosts = posts.filter((p) => p.userId !== currentUser.id)
  const [backgroundColors, setBackgroundColors] = useState([])
  const [filteredSubmissions, setFilteredSubmissions] = useState(
    forumPosts || []
  )

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const backgroundColors = []
    for (let i = 0; i <= forumPosts.length; i++) {
      backgroundColors.push(getRandomColor())
    }
    setBackgroundColors(backgroundColors)
  }, [forumPosts.length])

  useEffect(() => {
    if (!searchValue || searchValue === '') {
      setFilteredSubmissions(forumPosts)
      return
    }

    const queryOptions = {
      threshold: 0.2,
      keys: ['user.givenName', 'user.familyName1', 'user.familyName2'],
    }
    const fuse = new Fuse(forumPosts, queryOptions)
    const results = fuse.search(searchValue)
    setFilteredSubmissions(results.map((r) => r.item))
  }, [searchValue, forumPosts.length, posts])

  if (userPost) {
    forumPosts = [userPost, ...forumPosts]
  }

  if (forumPosts.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <NoAnswersText>No submissions</NoAnswersText>
      </div>
    )
  }
  const handlePostClick = (post) => {
    ParentInformer.sendEngagement({
      eventName: 'ask_post:open',
      elementType: 'ask',
      element: {
        uuid: assignment.id,
        title: assignment.title,
        author: {
          email: post.user.email,
          uuid: post.id,
        },
      },
    })
    dispatch(setSelectedPost(post.id))
  }

  return (
    <PostsGrid>
      {backgroundColors.length > 0 && filteredSubmissions
        .filter((post) => post.userId !== null)
        .map((post, i) => {
          const answerType = getAnswerType(post)
          const backgroundColor =
            backgroundColors.length > 0 ? backgroundColors[i] : undefined
          return (
            <Post
              key={post.id}
              onClick={() => handlePostClick(post)}
              answerType={answerType}
              answerTypes={answerTypes}
              backgroundColor={backgroundColor}
              userPost={userPost}
              user={currentUser}
              post={post}
              assignment={assignment}
              onAddComment={() => dispatch(setSelectedPost(post))}
            />
          )
        })}
    </PostsGrid>
  )
}
const PostsGrid = styled.div`
  display: grid;
  grid-gap: 1.2rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(1, 1fr);
  @media only screen and (min-width: 600px) {
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  margin: auto;
  padding: 0px;
  padding-bottom: 2rem;
  padding-top: 2rem;
  overflow: hidden;
`
const PostDetails = styled.div`
  left: 0;
  bottom: 0;
  padding: 0 12px 12px;
  width: 100%;
  padding-top: 2rem;
  position: absolute;
`
const PostContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
  ${(props) =>
    props.highlight
      ? `
    box-shadow: 0 0 0 1px #0E6EB8;
  `
      : ``}
  position: relative;
  aspect-ratio: 8/10;
  overflow: hidden;
  display: flex;
  background: ${(props) => {
    if (props.answerType === 'video') {
      return '#fff'
    }

    // random color
    return props.backgroundColor
  }};
  transition: all 0.3s ease-in-out !important;
  color: ${(props) => getContrastColorHex(props.backgroundColor)};
  flex-direction: column;
`
const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const UserName = styled.div`
  font-size: 0.8em;
  font-weight: 800;
  color: ${(props) => props.color};
`
const PostContent = styled.div`
  font-size: 1.3em;
  font-weight: 500;
  line-height: 1.2;
  width: 100%;
  flex: 1;
  font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif !important;
  color: ${(props) => props.color};
`
const Time = styled.p`
  font-size: 0.7em;
  color: ${(props) => props.color};
`
const PreviewContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => {
    if (props.answerType === 'video') {
      return '100%'
    }

    return '70%'
  }};
  justify-content: center;
  align-items: center;
  text-align: center;
`
const MicCircle = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 50%;
`
const NoAnswersText = styled.h4`
  color: ${(props) => props.theme.textTertiary};
`
