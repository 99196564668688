import styled, { useTheme } from 'styled-components'
import React, { useMemo, useState } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { useCreateForumPostMutation, useReviewForumPostMutation } from 'app/store/apiHubSlice'
import FileUploader from './FileUploader'
import ParentInformer from '../../../../utils/ParentInformer'
import { selectCurrentUser } from '../../../../store/authSlice'
import { selectEnrollmentId } from 'app/store/uiSlice'
import { useSelector } from 'react-redux'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import uploadMultipleFiles from '../../../../utils/uploadMultipleFiles'
import { getRandomColor } from 'app/utils/defaultColors'
import { HiSparkles } from 'react-icons/hi2'
import { AIFeedback } from './AIFeedback'
import { IoIosSend } from 'react-icons/io'

export default function NewPost({ assignment, documentId, onCancel }) {
  const [createForumPost, { isLoading: isCreatingPost }] =
    useCreateForumPostMutation()
  const [reviewForumPost, { isLoading: isReviewingPost }] =
    useReviewForumPostMutation()
  const [creating, setCreating] = useState(false)
  const [newPost, setNewPost] = React.useState({ title: '', content: '' })
  const [myFiles, setMyFiles] = useState([])
  const currentUser = useSelector(selectCurrentUser)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const theme = useTheme()
  const [errorMessage, setErrorMessage] = useState(null)
  const enrollmentId = useSelector(selectEnrollmentId)
  const [aiReviews, setAiReviews] = useState([])
  
  const retries = useMemo(() => {
    return assignment.allowedTries || 1
  }, [assignment])
  
  const retriesLeft = useMemo(() => {
    return retries - aiReviews.length
  }, [retries, aiReviews])

  const hasRetries = useMemo(() => {
    return retries > 1
  }, [retries])

  const createNewPost = async (files) => {
    try {
      const review = aiReviews.length > 0 ? aiReviews[aiReviews.length - 1] : null
      console.log('DEBUG review=', review)

      const forumPost = await createForumPost({
        userId: currentUser.id,
        assignmentId: assignment.id,
        documentId: documentId,
        content: newPost.content,
        files: files,
        title: assignment.title,
        details: {
          backgroundColor: getRandomColor(),
        },
        enrollmentId,
        feedbackId: review?.id,
      })

      if (!forumPost.data) {
        setErrorMessage('Ocurrió un error al publicar tu entrada.')
        return
      }

      window.location.reload()
      //TODO: invalidae cache to refetch posts
      // dispatch(setStudentAnsweredForum(true));
      ParentInformer.sendSubmission(assignment)
    } catch (error) {
      console.log(error)
    }
  }

  const reviewPost = async () => {
    try {
      const review = await reviewForumPost({
        postBody: newPost.content,
        assignmentId: assignment.id,
      })
      
      if (review.error) {
        throw new Error(review.error.data.message)
      }

      setAiReviews([...aiReviews, review.data?.data])
      console.log('DEBUG aiReviews=', aiReviews)
    } catch (error) {
      ParentInformer.sendToast({
        embedId: assignment.id,
        message: error.message,
        type: 'error',
      })
    }
  }

  const handleEditorChanged = (state) => {
    setEditorState(state)
    setNewPost({
      ...newPost,
      content: draftToHtml(convertToRaw(state.getCurrentContent())),
    })
  }

  const handleCreatePost = async () => {
    if (newPost.content.length < 9) {
      return
    }

    setErrorMessage(null)
    setCreating(true)

    try {
      const files = await uploadMultipleFiles(
        myFiles,
        `assigments/${assignment.id}/${currentUser.id}`
      )
      await createNewPost(files)
    } catch (err) {
      setErrorMessage('Ocurrió un error al publicar tu entrada.')
    }
    setCreating(false)
  }

  if (aiReviews.length > 0) {
    return (
      <EditorContainer>
        <AIFeedbackContainer>
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChanged}
            toolbar={{
              options: ['inline', 'blockType', 'list'],
              blockType: {
                inDropdown: false,
              },
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
            }}
            placeholder="Escribe aquí..."
            editorStyle={{
              backgroundColor: theme.sidebarBackground,
              color: theme.text,
              border: `1px solid ${theme.cardBorder}`,
              borderRadius: '4px',
            }}
            toolbarStyle={{
              backgroundColor: theme.sidebarBackground,
              color: theme.text,
              border: `1px solid ${theme.cardBorder}`,
              borderRadius: '4px',
            }}
          />
          <AIFeedback
            userName="Navi"
            feedback={aiReviews[aiReviews.length - 1].feedback}
            rating={aiReviews[aiReviews.length - 1].suggestedGrade}
          />
          <ActionsContainer>
            <Button
              disabled={isCreatingPost || creating}
              loading={isCreatingPost || creating}
              onClick={handleCreatePost}
              secondary
            >
              <ButtonIconContainer>
                <IoIosSend />
                Finalizar entrega
              </ButtonIconContainer>
            </Button>
            <Popup
              content="¡Debes escribir algo en tu entrada!"
              on="hover"
              disabled={newPost.content.length >= 9}
              style={{ margin: 0 }}
              trigger={
                <Button
                  disabled={isReviewingPost || retriesLeft <= 0}
                  loading={isReviewingPost}
                  primary
                  onClick={reviewPost}
                  style={{ margin: 0 }}
                >
                  <ButtonIconContainer>
                    <HiSparkles />
                    Revisar
                  </ButtonIconContainer>
                </Button>
              }
            />
          </ActionsContainer>
          <RetriesInfoContainer>
            <RetriesBadge>
              {retriesLeft} {retriesLeft === 1 ? 'intento' : 'intentos'} {retriesLeft === 1 ? 'restante' : 'restantes'}
            </RetriesBadge>
          </RetriesInfoContainer>
        </AIFeedbackContainer>
      </EditorContainer>
    )
  }

  return (
    <EditorContainer>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChanged}
        toolbar={{
          options: ['inline', 'blockType', 'list'],
          blockType: {
            inDropdown: false,
          },
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
        }}
        placeholder="Escribe aquí..."
        editorStyle={{
          backgroundColor: theme.sidebarBackground,
          color: theme.text,
          border: `1px solid ${theme.cardBorder}`,
          borderRadius: '4px',
        }}
        toolbarStyle={{
          backgroundColor: theme.sidebarBackground,
          color: theme.text,
          border: `1px solid ${theme.cardBorder}`,
          borderRadius: '4px',
        }}
      />
      <FileUploader myFiles={myFiles} setMyFiles={setMyFiles} />
      <ActionsContainer>
        <Button onClick={() => onCancel()}>Cancelar</Button>
        <Popup
          content="¡Debes escribir algo en tu entrada!"
          on="hover"
          disabled={newPost.content.length >= 9}
          style={{ margin: 0 }}
          trigger={
            <Button
              disabled={isCreatingPost || creating || isReviewingPost}
              loading={isCreatingPost || creating || isReviewingPost}
              primary
              onClick={hasRetries ? reviewPost : handleCreatePost}
              style={{ margin: 0 }}
            >
              {hasRetries ? (
                <ButtonIconContainer>
                  <HiSparkles />
                  Revisar
                </ButtonIconContainer>
              ) : (
                'Guardar'
              )}
            </Button>
          }
        />
      </ActionsContainer>
      {errorMessage && (
        <div style={{ textAlign: 'right' }}>
          <small style={{ color: 'red' }}>{errorMessage}</small>
        </div>
      )}
    </EditorContainer>
  )
}

const EditorContainer = styled.div`
  overflow: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
`

const ButtonIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: inherit;
  gap: 5px;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const RetriesInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: inherit;
`

const AIFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`

const RetriesBadge = styled.div`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 14px;
  background-color: rgba(231, 0, 11, 0.09);
  color: rgba(231, 0, 11, 0.5);
  font-size: 0.9rem;
  font-weight: bold;
`