import React from 'react';
import styled from 'styled-components';

/**
 * AI Feedback Component
 * Props:
 *   userName:   string — name or identifier of the AI
 *   feedback:   string — the text of the feedback
 *   rating:     number or string — the feedback rating
 */
export const AIFeedback = ({ userName, feedback, rating }) => {
  return (
    <Card rating={rating}>
      <HeaderContainer>
        {/* <Header>
          <Avatar src='/icons/navi-icon.png'/>
          <Name>{userName}</Name>
        </Header> */}
        <AIFeedbackLabel rating={rating}>
          Retroalimentación
        </AIFeedbackLabel>
        <Rating rating={rating}>Calificación: {rating}</Rating>
      </HeaderContainer>
      <FeedbackText rating={rating}>{feedback}</FeedbackText>
    </Card>
  );
}

/* ——————————— Styled Components ——————————— */

// Main wrapper
const Card = styled.div`
  // width: 400px;
  padding: 16px;
  margin: 4px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(0,0,0,0.1);
  font-family: sans-serif;
`;

// Header section
const Header = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
`;

// Circle avatar (initial-based)
const Avatar = styled.img`
  width: 30px;
  height: 30px;
  background-color: #576ce4;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
`;

// User name
const Name = styled.h3`
  margin: 0;
  font-size: 1.1rem;
  color: #333;
`;

// The feedback text
const FeedbackText = styled.p`
  margin: 0 0 12px 0;
  font-size: 0.95rem;
  line-height: 1.4;
`;

// Rating badge / text
const Rating = styled.span`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 14px;
  background-color: ${props => props.rating >= 6 ? 'rgba(9, 189, 90, 0.1)' : 'rgba(245, 89, 89, 0.1)'};
  color: ${props => props.rating >= 6 ? 'rgba(9, 189, 90, 1)' : 'rgba(245, 89, 89, 1)'};
  font-size: 0.9rem;
  font-weight: bold;
`;

const AIFeedbackLabel = styled.div`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 14px;
  background-color: rgba(0, 0, 0, 0.09);
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.9rem;
`;