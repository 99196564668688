import React from 'react'
import { Button, Form } from 'semantic-ui-react'
import styled from 'styled-components'
import { FaTrash } from 'react-icons/fa'

const FileUploader = ({ myFiles, setMyFiles }) => {
  const fileInputRef = React.createRef()

  const handleFiles = (e) => {
    setMyFiles(myFiles.concat(Array.prototype.slice.call(e.target.files)))
  }
  const removeFile = (file) => {
    setMyFiles(
      myFiles.filter((f) => {
        const identifier = file.name || file.file_name
        const identifierName = f.name ? 'name' : 'file_name'
        return f[identifierName] !== identifier
      })
    )
  }

  return (
    <>
      <Form style={{ marginTop: '10px' }}>
        <Button
          content="Sube fotos, videos y archivos"
          icon="picture"
          onClick={() => fileInputRef.current.click()}
          style={{ marginBottom: '1rem' }}
          basic
        />
        <input
          ref={fileInputRef}
          type="file"
          multiple
          hidden
          onChange={handleFiles}
        />
      </Form>
      <Flex gap={15} align="flex-start" justify="flex-start" wrap="wrap">
        {myFiles.map((x) => (
          <File key={x.name} onClick={() => removeFile(x)}>
            <FaTrash size={14} /> {x.name || x.file_name}
          </File>
        ))}
      </Flex>
    </>
  )
}

export default FileUploader

const Flex = styled.div`
  display: flex;
  width: 100%;
  gap: ${(props) => props.gap || 0}px;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align || 'center'};
  margin: ${(props) => props.margin || 0}px;
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
`

const File = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  svg {
    padding-right: 3px;
  }
  &:hover {
    cursor: pointer;
    color: red;
    svg {
      fill: red;
    }
  }
`
