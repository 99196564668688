import React, { useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Checkbox, Button, Input, Loader, Popup } from 'semantic-ui-react'
import Flex from '../../components/containers/Flex'
import TypeFormConfig from './components/TypeFormConfig'
import ForumConfig from './components/ForumConfig'
import FileUploadConfig from './components/FileUploadConfig'
import ExamConfig from './components/ExamConfig'
import PollConfig from './components/PollConfig'
import {
  setAssigment,
  selectAssigmentByType,
  selectReady,
  selectAutoCreatingAssignment,
  setAutoCreatingAssignment,
  selectErrorMessage,
} from '../../store/assigmentCreationSlice'
import {
  useCreateAssignmentMutation,
  useGetAssignmentTypesQuery,
  useGetDocumentByIdQuery,
} from 'app/store/apiHubSlice'
import { useDispatch, useSelector } from 'react-redux'
import uploadFile from '../../utils/uploadFile'
import { DragAndDropConfig } from './components/DragAndDropConfig'
import { CodingConfig } from './components/CodingConfig'
import QuestionsOrderDropdown from './components/ExamConfig/QuestionsOrderDropdown'
import { TitleRow } from './components/TitleRow'
import { TextArea } from 'app/components/TextArea'

async function uploadFiles(files, questionId) {
  const uploadedFiles = await Promise.all(
    files.map(async (file) => {
      const x = await uploadFile(file, `exams/images/${questionId}`)
      return x
    })
  )
  return uploadedFiles
}

const NOT_ALLOWED_FEEDBACK_SLUGS = ['exam']

export default function AssignmentCreator({ documentId, assignmentId, type }) {
  let dispatch = useDispatch()
  const assigment = useSelector(selectAssigmentByType(type))
  const ready = useSelector(selectReady)
  const autoCreatingAssignment = useSelector(selectAutoCreatingAssignment)
  const [createAssigment, { isLoading }] = useCreateAssignmentMutation()
  const { data } = useGetAssignmentTypesQuery()
  const documentQuery = useGetDocumentByIdQuery(documentId)
  const errorMessage = useSelector(selectErrorMessage)
  const theme = useTheme()
  let content
  let assignmentTypeConfiguration

  const autoCreateAssignment = () => {
    ;(async () => {
      try {
        const requestObject = {
          id: assignmentId,
          documentId: documentId,
          title: assigment.assignmentType.displayText || 'Actividad',
          assignmentTypeId: assigment.assignmentType.id,
          configuration: {
            isChallenge: false,
            requiresFeedback: false,
            isSynchronous: assigment.configuration.isSynchronous,
            isOpen: Object.hasOwn(assigment.configuration, 'isSynchronous')
              ? !assigment.configuration.isSynchronous
              : true,
            questionsOrder:
              assigment?.configuration?.questionsOrder ?? 'sequence',
          },
          code: assigment.code,
          aiContext: assigment.aiContext,
          enableAiFeedback: assigment.enableAiFeedback,
          allowedTries: assigment.allowedTries,
        }

        if (!isLoading) {
          await createAssigment({ assignment: requestObject }).unwrap()
          window.location.reload()
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }

  const handleCreateAssigment = async () => {
    try {
      let requestObject = {
        id: assignmentId,
        documentId: documentId,
        title: assigment.title,
        description: assigment.description,
        assignmentTypeId: assigment.assignmentType.id,
        configuration: {
          ...assigment.configuration,
          isOpen: Object.hasOwn(assigment.configuration, 'isSynchronous')
            ? !assigment.configuration.isSynchronous
            : true,
        },
        code: assigment.code,
        aiContext: assigment.aiContext,
        enableAiFeedback: assigment.enableAiFeedback,
        allowedTries: assigment.allowedTries,
      }

      // Upload exam images
      if (assigment.assignmentType.slug === 'exam') {
        let instantFeedback = true
        const quiz = assigment.configuration.quiz.map((obj) => ({
          ...obj,
        }))

        for (let i = 0; i < quiz.length; i++) {
          if (quiz[i].imageFile) {
            const files = await uploadFiles([quiz[i].imageFile], quiz[i].id)
            delete quiz[i].imageFile
            quiz[i].imageUrl = files[0].file_url
            quiz[i].isSelfHosted = true
          }

          if (quiz[i].element === 'MultiLineInput') {
            instantFeedback = false
          }
        }

        requestObject = {
          ...requestObject,
          configuration: {
            ...requestObject.configuration,
            questionsOrder:
              assigment?.configuration?.questionsOrder ?? 'sequence',
            quiz,
            instantFeedback,
            requiresFeedback: !instantFeedback
              ? true
              : requestObject.configuration.requiresFeedback,
          },
        }
      }

      // Upload drag n drop images
      if (assigment.assignmentType.slug === 'drag_n_drop') {
        for (
          let i = 0;
          i < requestObject.configuration.rightSideConcepts.length;
          i++
        ) {
          let concept = requestObject.configuration.rightSideConcepts[i]

          if (Object.hasOwn(concept, 'image')) {
            const uploadedImage = await uploadFiles([concept.image], concept.id)
            concept = {
              ...concept,
              imageUrl: uploadedImage[0].file_url,
            }
            delete concept.image

            // Update request object
            const updatedRightSideConcepts = [
              ...requestObject.configuration.rightSideConcepts,
            ]
            updatedRightSideConcepts[i] = concept
            requestObject.configuration.rightSideConcepts =
              updatedRightSideConcepts
          }
        }
      }

      if (!isLoading) {
        await createAssigment({ assignment: requestObject }).unwrap()
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCheckboxChange = (e, data) => {
    let config = { ...assigment.configuration }
    config[data.name] = data.checked
    dispatch(setAssigment({ ...assigment, configuration: config }))
  }

  const handleImageChange = (e) => {
    let config = { ...assigment.configuration }
    config.image = e.target.value
    dispatch(setAssigment({ ...assigment, configuration: config }))
  }

  const handleQuizzConfig = (newConfig = {}) => {
    const config = { ...assigment?.configuration, ...newConfig }

    dispatch(setAssigment({ ...assigment, configuration: config }))
  }

  const handleAiFeedbackChange = (e, data) => {
    let allowedTries = assigment.allowedTries

    if (assigment.assignmentType?.slug === 'forum' && data.checked && !assigment.enableAiFeedback) {
      allowedTries = 3
    }

    dispatch(setAssigment({
      ...assigment,
      enableAiFeedback: data.checked,
      allowedTries
    }))
  }

  useEffect(() => {
    if (
      type &&
      assigment.assignmentType === null &&
      data &&
      data.data &&
      documentQuery.data?.data
    ) {
      const assignmentType = data.data.find((aType) => aType.slug === type)
      const collection = documentQuery.data?.data.collection
      if (assignmentType) {
        dispatch(
          setAssigment({
            ...assigment,
            assignmentType: {
              id: assignmentType.id,
              slug: assignmentType.slug,
              displayText: assignmentType.displayText,
              name: assignmentType.name,
            },
            configuration: {
              ...assigment.configuration,
              isChallenge: collection.isPlayground
                ? false
                : assigment.configuration.isChallenge,
              requiresFeedback: collection.isPlayground
                ? false
                : assigment.configuration.requiresFeedback,
            },
          })
        )
      }
    }
  }, [assigment, data, documentQuery.data, dispatch, type])

  if (assigment.assignmentType) {
    switch (assigment.assignmentType.slug) {
      case 'typeform':
        assignmentTypeConfiguration = <TypeFormConfig />
        break
      case 'typeform_quiz':
        assignmentTypeConfiguration = <TypeFormConfig />
        break
      case 'forum':
        assignmentTypeConfiguration = <ForumConfig format="standard" />
        break
      case 'q_a':
        assignmentTypeConfiguration = <ForumConfig format="small" />
        break
      case 'file_upload':
        assignmentTypeConfiguration = <FileUploadConfig />
        break
      case 'exam':
        assignmentTypeConfiguration = <ExamConfig />
        break
      case 'poll':
        assignmentTypeConfiguration = <PollConfig />
        break
      case 'drag_n_drop':
        assignmentTypeConfiguration = <DragAndDropConfig />
        break
      case 'board':
        if (!ready && !autoCreatingAssignment) {
          dispatch(setAutoCreatingAssignment(true))
          autoCreateAssignment()
        }
        assignmentTypeConfiguration = <></>
        break
      default:
        assignmentTypeConfiguration = <CodingConfig />
        break
    }
  } else if (
    (type === null || type === undefined || type === 'undefined') &&
    documentQuery.data
  ) {
    content = (
      <Flex column>
        <Heading>Nueva actividad</Heading>
        <Subheader>
          Selecciona el tipo de actividad que quieres crear.
        </Subheader>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {data &&
            data.data &&
            data.data
              .filter(
                (aType) =>
                  aType.slug !== 'board' &&
                  aType.slug !== 'poll' &&
                  aType.slug !== 'blockly'
              )
              .map((aType) => {
                return (
                  <Button
                    key={aType.id}
                    onClick={() => {
                      dispatch(
                        setAssigment({
                          ...assigment,
                          assignmentType: {
                            id: aType.id,
                            slug: aType.slug,
                            displayText: aType.displayText,
                            name: aType.name,
                          },
                          configuration: {
                            ...assigment.configuration,
                            image:
                              aType.slug === 'exam'
                                ? ''
                                : assigment.configuration.image,
                            isChallenge: documentQuery.data.data.collection
                              .isPlayground
                              ? false
                              : assigment.configuration.isChallenge,
                            requiresFeedback: documentQuery.data.data.collection
                              .isPlayground
                              ? false
                              : assigment.configuration.requiresFeedback,
                          },
                        })
                      )
                    }}
                    inverted={theme.isDark}
                  >
                    {aType.displayText}
                  </Button>
                )
              })}
        </div>
      </Flex>
    )
  }

  const AllowsFeedBack =
    assigment &&
    !NOT_ALLOWED_FEEDBACK_SLUGS.includes(assigment?.assignmentType?.slug)

  if (type && type === 'board') {
    content = <Loader active />
  } else if (assigment && assigment.assignmentType && documentQuery.data) {
    content = (
      <>
        {/* Basic config */}
        <FormRow style={{ flexDirection: 'row' }}>
          {!documentQuery.data?.data?.collection?.isPlayground ? (
            <>
              {AllowsFeedBack && (
                <CustomCheckbox
                  label="Requiere revisión"
                  name="requiresFeedback"
                  checked={assigment.configuration.requiresFeedback}
                  style={{ marginRight: '1rem' }}
                  onChange={handleCheckboxChange}
                />
              )}
              <CustomCheckbox
                name="isChallenge"
                label="Challenge"
                checked={assigment.configuration.isChallenge}
                style={{ marginRight: '1rem' }}
                onChange={handleCheckboxChange}
              />
              <CustomCheckbox
                name="enableAiFeedback"
                label="AI Feedback"
                checked={assigment.enableAiFeedback}
                style={{ marginRight: '1rem' }}
                onChange={handleAiFeedbackChange}
              />
              <CustomCheckbox
                name="isSynchronous"
                label="Actividad síncrona"
                checked={assigment.configuration.isSynchronous}
                style={{ marginRight: '1rem' }}
                onChange={handleCheckboxChange}
              />
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '5px',
              }}
            >
              <Checkbox
                name="isSynchronous"
                label="Actividad síncrona"
                checked={assigment.configuration.isSynchronous}
                onChange={handleCheckboxChange}
              />
            </div>
          )}
        </FormRow>
        {/* Name */}
        <FormRow style={{ marginTop: '1rem' }}>
          <span>Nombre de la actividad</span>
          {/* Code */}
          <TitleRow>
            <Input
              placeholder="Nomenclatura"
              value={assigment.code ?? ''}
              onChange={(e) =>
                dispatch(
                  setAssigment({
                    ...assigment,
                    code: e.target.value,
                  })
                )
              }
            />
            {/* Title */}
            <Input
              placeholder="Título"
              style={{ width: '100%' }}
              value={assigment.title}
              onChange={(e) =>
                dispatch(
                  setAssigment({
                    ...assigment,
                    title: e.target.value,
                  })
                )
              }
            />
          </TitleRow>
        </FormRow>
        {/* Additional context */}
        {assigment?.enableAiFeedback && (
          <FormRow style={{ marginTop: '1rem' }}>
            <span>Contexto adicional</span>
            <TextArea
              placeholder="Agrega contexto para que la AI califique mejor esta actividad..."
              style={{ width: '100%' }}
              value={assigment.aiContext}
              onChange={(e) =>
                dispatch(
                  setAssigment({
                    ...assigment,
                    aiContext: e.target.value,
                  })
                )
              }
            />
          </FormRow>
        )}
        {/* Allowed tries */}
        {assigment?.enableAiFeedback && (
          <FormRow style={{ marginTop: '1rem' }}>
            <span>Intentos permitidos</span>
            <Input
              placeholder="Intentos permitidos"
              value={assigment.allowedTries}
              type="number"
              min={1}
              onChange={(e) =>
                dispatch(setAssigment({ ...assigment, allowedTries: e.target.value }))
              }
            />
          </FormRow>
        )}
        {/* Cover image */}
        {(assigment.assignmentType.slug === 'forum' ||
          assigment.assignmentType.slug === 'exam') && (
          <>
            <FormRow style={{ marginTop: '15px' }}>
              <span>URL de la imagen</span>
              <Input
                placeholder="https://img.png"
                style={{ width: '100%' }}
                value={assigment.configuration.image}
                onChange={handleImageChange}
              />
            </FormRow>
            {assigment.configuration.image.length > 1 && (
              <FormRow>
                <Cover src={assigment.configuration.image} />
              </FormRow>
            )}
          </>
        )}
        {/* Assignment configuration */}
        {assignmentTypeConfiguration}
        {/* Create options */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            gap: '15px',
          }}
        >
          <div className="flex gap-2 items-center w-full">
            <Button
              onClick={() =>
                dispatch(
                  setAssigment({
                    ...assigment,
                    assignmentType: null,
                  })
                )
              }
              inverted={theme.isDark}
            >
              Regresar
            </Button>
            {assigment?.assignmentType?.slug === 'exam' && (
              <>
                <QuestionsOrderDropdown
                  upward={true}
                  placeholder={`Orden de preguntas: ${
                    assigment?.configuration?.questionsOrder ?? 'sequence'
                  }`}
                  value={assigment?.configuration?.questionsOrder ?? 'sequence'}
                  onChange={(_, { value }) => {
                    handleQuizzConfig({
                      questionsOrder: value,
                    })
                  }}
                />
              </>
            )}
          </div>

          <div>
            <Popup
              content={errorMessage}
              on="hover"
              disabled={errorMessage === null}
              trigger={
                <div>
                  <Button
                    disabled={!ready || assigment.title.length < 3 || isLoading}
                    loading={isLoading}
                    primary
                    onClick={handleCreateAssigment}
                  >
                    Crear
                  </Button>
                </div>
              }
            />
            <small style={{ color: 'red' }}>{}</small>
          </div>
        </div>
      </>
    )
  }

  return (
    <Flex full>
      <Container>{content}</Container>
    </Flex>
  )
}

const Heading = styled.p`
  font-weight: bold;
  font-size: 2em;
`
const FormRow = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 5px;

  input {
    background-color: ${(props) => props.theme.sidebarBackground} !important;
    color: ${(props) => props.theme.text} !important;
  }
`
const Container = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  display: flex;
  padding: 25px 50px;
  width: 100%;
`
const Cover = styled.div`
  width: 100%;
  height: 200px;
  background: url(${(props) => props.src});
  background-size: cover;
`
const Subheader = styled.p`
  color: ${(props) => props.theme.textSecondary};
`
const CustomCheckbox = styled(Checkbox)`
  label {
    color: ${(props) => props.theme.textSecondary} !important;
  }
`
